import React from 'react'

// Components
import SEO from '../components/seo'
import TableOfContents from '../components/TableOfContents'

// Style
import style from './404.module.css'

const NotFoundPage = ({ location }) => (
	<section className={ style.NotFound }>
		<SEO title='404: Not found' url={ location.href } />
		<h1 className={ style.h1 }>Page Not Found</h1>
		<p>You just hit a URL that doesn’t exist. Perhaps you were looking for one of these chapters?</p>
		<div className={ style.TOC }>
			<TableOfContents location={ location } withHome={ false } />
		</div>
	</section>
)

export default NotFoundPage
